// Change Bootstrap color schema & some aspect

$green: #198754
$primary: $green

$form-check-input-width: 1.25em
$form-check-input-border: 2px solid #000000

$nav-tabs-border-color: white
$badge-padding-x: 10px

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss'

// Custom classes
.btn-primary
  --bs-btn-font-weight: 500
  --bs-btn-color: var(--bs-white)
  --bs-btn-disabled-color: var(--bs-white)
  --bs-btn-bg: #{$primary}
  --bs-btn-border-color: #{$primary}
  --bs-btn-hover-color: var(--bs-white)
  --bs-btn-hover-bg: #{shade-color($primary, 10%)}
  --bs-btn-hover-border-color: #{shade-color($primary, 10%)}
  --bs-btn-active-color: var(--bs-btn-hover-color)
  --bs-btn-active-bg: #{shade-color($primary, 20%)}
  --bs-btn-active-border-color: #{shade-color($primary, 20%)}

.bg-gray-100
  background-color: #eeeeee

.list-group-overflowed
  max-height: 60vh
  overflow-y: auto
  overflow-x: hidden

.min-h-60
  min-height: 60vh

.form-check-input
  flex-shrink: 0
  cursor: pointer
.form-check-label
  cursor: pointer

.btn-no-border
  border-color: transparent !important

.offers-list
  max-height: 119px
  overflow: auto
  background-color: #eeeeee
  border-radius: 10px
  -ms-overflow-style: none
  scrollbar-width: none
  margin-left: -0.1rem !important

.offers-list::-webkit-scrollbar
  display: none

.card
  border: 1px solid rgba(0,34,51,.1)
  box-shadow: rgba(0, 34, 51, 0.1) 0px 1px 4px -1px
